import * as React from "react";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import IconButton, { IconButtonProps } from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeRoundedIcon from "@mui/icons-material/LightModeRounded";
import BadgeRoundedIcon from "@mui/icons-material/BadgeRounded";
import { Card, DialogTitle, Modal, ModalDialog } from "@mui/joy";
import axios from "axios";
import ValidationSnackbar from "./ValidationSnackbar";
import { URL } from "../globals";

axios.defaults.withCredentials = true;

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function AddNewUser(props: {
  setAddUser: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [addInfo, setAddInfo] = React.useState<string>("");
  const [addSuccess, setAddSuccess] = React.useState<
    "success" | "warning" | "primary" | "neutral" | "danger" | undefined
  >(undefined);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  async function add_user(email: string, password: string) {
    await axios
      .post(
        URL + "add_user",
        { id: 1, email: email, passwd: password },
        { withCredentials: true },
      )
      .then(function (response) {
        console.log(response);
        setAddSuccess("success");
        setAddInfo("Uspešno dodal uporabnika " + email);
        setOpenSnackbar(true);
      })
      .catch(function (error) {
        console.log(error);
        setAddSuccess("danger");
        setAddInfo(error.code + ": " + error.message);
        setOpenSnackbar(true);
      });
  }
  return (
    // <CssVarsProvider defaultMode="dark">
    <>
      <CssBaseline />
      <Modal open={props.open} onClose={() => props.setOpen(false)}>
        <ModalDialog size="lg" layout="center">
          <DialogTitle>Dodaj novega uporabnika</DialogTitle>
          <Stack spacing={1}>
            <form
              onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                event.preventDefault();
                const formElements = event.currentTarget.elements;
                add_user(formElements.email.value, formElements.password.value);
                props.setOpen(false);
              }}
            >
              <FormControl required>
                <FormLabel>Email</FormLabel>
                <Input type="email" name="email" />
              </FormControl>
              <FormControl required>
                <FormLabel>Geslo</FormLabel>
                <Input type="password" name="password" />
              </FormControl>
              <Stack gap={2} sx={{ mt: 2, px: 10 }}>
                <Button type="submit" fullWidth>
                  Dodaj
                </Button>
                <Button
                  variant="soft"
                  onClick={() => {
                    props.setAddUser(false);
                  }}
                >
                  Prekliči
                </Button>
              </Stack>
            </form>
          </Stack>
        </ModalDialog>
      </Modal>
      <ValidationSnackbar
        info={addInfo}
        color={addSuccess}
        open={openSnackbar}
        setOpen={setOpenSnackbar}
      />

      {/* <Card
        sx={{
          display: "flex",
          maxWidth: "450px",
          mx: "auto",
          px: { xs: 2, md: 6 },
          // py: { xs: 2, md: 6 },
        }}
      >
        <Box sx={{ mb: 1, mt: 3 }}>
          {/* <Typography level="title-lg">HUDNIK-DB</Typography>
                    <Typography level="body-sm">
                        Aplikacija za urejanje podatkov strani cedez.si, hudnik.si, ggs.si...
                    </Typography>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mt: 2 }}>
              <Typography level="title-lg">Dodaj uporabnika</Typography>
              <form
                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  add_user(
                    formElements.email.value,
                    formElements.password.value,
                  );
                }}
              >
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>
                <FormControl required>
                  <FormLabel>Geslo</FormLabel>
                  <Input type="password" name="password" />
                </FormControl>
                <Stack gap={2} sx={{ mt: 2, px: 10 }}>
                  <Button type="submit" fullWidth>
                    Dodaj
                  </Button>
                  <Button
                    variant="soft"
                    onClick={() => {
                      props.setAddUser(false);
                    }}
                  >
                    Prekliči
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
        </Box>
      </Card> */}
    </>
  );
}
