import * as React from "react";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel, { formLabelClasses } from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import { Card, IconButton, useColorScheme } from "@mui/joy";
import axios from "axios";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { URL } from "../globals";

import LoginError from "./LoginError";

axios.defaults.withCredentials = true;

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function Login(props: {
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  setIsGuest: React.Dispatch<React.SetStateAction<boolean>>;
  setUserEmail: React.Dispatch<React.SetStateAction<String>>;
}) {
  const [loginError, setLoginError] = React.useState(false);
  const { mode, setMode } = useColorScheme();

  function login(email: string, password: string) {
    axios
      .post(
        URL + "login",
        { id: 1, email: email, passwd: password },
        { withCredentials: true },
      )
      .then(function (response) {
        props.setUserEmail(response.data);
        setLoginError(false);
        props.setLoggedIn(true);
      })
      .catch(function (error) {
        setLoginError(true);
        console.log(error);
        props.setLoggedIn(false);
      });
  }
  return (
    // <CssVarsProvider defaultMode="dark">
    <>
      <CssBaseline />

      <Card
        sx={{
          display: "flex",
          maxWidth: "800px",
          mx: "auto",
          px: { xs: 2, md: 6 },
          py: { xs: 2, md: 6 },
        }}
      >
        <Box sx={{ mb: 1, mt: 3 }}>
          <Box
            sx={{
              position: "absolute",
              top: "0.875rem",
              left: "1rem",
            }}
          >
            <IconButton
              onClick={() => setMode(mode === "light" ? "dark" : "light")}
            >
              {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </Box>

          <Typography level="title-lg">HUDNIK-DB</Typography>
          <Typography level="body-sm">
            Aplikacija za urejanje podatkov strani cedez.si, hudnik.si,
            ggs.si...
          </Typography>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: "hidden",
              },
            }}
          >
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  login(formElements.email.value, formElements.password.value);
                }}
              >
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>
                <FormControl required>
                  <FormLabel>Geslo</FormLabel>
                  <Input type="password" name="password" />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button type="submit" fullWidth>
                    Potrdi
                  </Button>
                </Stack>
                <Button
                  variant="soft"
                  onClick={() => {
                    props.setIsGuest(true);
                    props.setUserEmail("Gost");
                  }}
                >
                  Vstopi kot gost
                </Button>
              </form>
              <Stack gap={4} sx={{ mt: 2 }}>
                <LoginError loginError={loginError} />
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Card>
    </>
  );
}
