import React, { useEffect } from "react";
import "./App.css";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import {
  Button,
  ButtonGroup,
  CssBaseline,
  CssVarsProvider,
  Divider,
  Switch,
  Tooltip,
  useColorScheme,
} from "@mui/joy";
import axios from "axios";
import { Css, Logout, PersonAdd } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import Editor from "./components/Editor";
import Login from "./components/Login";
import AddNewUser from "./components/AddNewUser";
import KeyIcon from "@mui/icons-material/Key";
import ChangePassword from "./components/ChangePassword";
import Concerts from "./components/Concerts";
import { URL } from "./globals";

function check_login(): boolean {
  axios
    .get(URL + "test_login", {
      withCredentials: true,
    })
    .then((response: { statusText: string; data: string }) => {
      console.log(response.data);
      return true;
    })
    .catch(function (error) {
      console.log(error);
      return false;
    });
  return false;
}

function App() {
  useEffect(() => {
    document.title = "Hudnik-DB";
  }, []);

  // theme selector
  const { mode, setMode } = useColorScheme();

  const [loggedIn, setLoggedIn] = React.useState<boolean>(false);
  const [isGuest, setIsGuest] = React.useState<boolean>(false);
  const [addUser, setAddUser] = React.useState<boolean>(false);
  const [userEmail, setUserEmail] = React.useState<String>("");
  const [openNewPassword, setOpenNewPassword] = React.useState<boolean>(false);
  const [checkingContent, setCheckingContent] = React.useState<boolean>(true);

  React.useEffect(() => {
    console.log("Sending initial test_login request...");
    axios
      .get(URL + "test_login", {
        withCredentials: true,
      })
      .then((response: { statusText: string; data: string }) => {
        setLoggedIn(true);
        setUserEmail(response.data);
        console.log("LoggedIn: " + loggedIn);
      })
      .catch(function (error) {
        console.log(error);
        setLoggedIn(false);
      });
  });

  useEffect(() => {
    if (!isGuest) {
      const interval = setInterval(async () => {
        console.log("Sending interval test_login request...");

        await axios
          .get(URL + "test_login", {
            withCredentials: true,
          })
          .then((response: { statusText: string; data: string }) => {
            setLoggedIn(true);
            setUserEmail(response.data);
            // console.log("Success! LoggedIn interval: " + loggedIn);
          })
          .catch(function (error) {
            console.log(error);
            // console.log("Failure! LoggedIn interval: " + loggedIn);
            setLoggedIn(false);
          });
        console.log("LoggedIn interval: " + loggedIn);
      }, 10000);

      return () => clearInterval(interval);
    }
  }, []);

  if (loggedIn || isGuest) {
    return (
      // <CssVarsProvider defaultMode="dark">
      <div className="App">
        <body>
          <CssBaseline />
          <Card
            sx={{
              display: "flex",
              maxWidth: "1000px",
              mx: "auto",
              px: { xs: 2, md: 6 },
              py: { xs: 6, md: 6 },
            }}
          >
            <Box sx={{ mb: 1, mt: "3rem" }}>
              <Box sx={{ mb: 3 }}>
                <div>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0.875rem",
                      left: "1rem",
                    }}
                  >
                    <IconButton
                      onClick={() =>
                        setMode(mode === "light" ? "dark" : "light")
                      }
                    >
                      {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
                    </IconButton>
                  </Box>

                  <Typography level="title-lg" sx={{ top: "0.5rem" }}>
                    HUDNIK-DB
                  </Typography>
                  <Typography level="body-sm">
                    Aplikacija za urejanje podatkov s strani cedez.si,
                    hudnik.si, ggs.si...
                  </Typography>

                  <Box
                    sx={{
                      position: "absolute",
                      top: "0.875rem",
                      right: "1rem",
                    }}
                  >
                    {/* <Typography level="title-md">{userEmail} </Typography> */}
                    {/* <Divider /> */}
                    <ButtonGroup size="lg" spacing="0.5rem" sx={{ mx: "auto" }}>
                      <Tooltip
                        arrow
                        title={
                          isGuest
                            ? "Prijavljen kot gost"
                            : "Prijavljen kot " + userEmail
                        }
                      >
                        <IconButton
                          aria-label="bookmark Bahamas Islands"
                          // disabled={isGuest}
                          // variant="plain"
                          color="neutral"
                          // size="sm"
                          onClick={() => {
                            // setAddUser(true);
                          }}
                        >
                          <PersonIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Dodaj uporabnika">
                        <IconButton
                          aria-label="bookmark Bahamas Islands"
                          disabled={
                            isGuest || userEmail != "hudnik.izak@gmail.com"
                          }
                          // variant="plain"
                          color="neutral"
                          // size="sm"
                          onClick={() => {
                            setAddUser(true);
                          }}
                        >
                          <PersonAdd />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Spremeni geslo">
                        <IconButton
                          aria-label="bookmark Bahamas Islands"
                          disabled={isGuest}
                          // variant="plain"
                          color="neutral"
                          // size="sm"
                          onClick={() => {
                            setOpenNewPassword(true);
                          }}
                        >
                          <KeyIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title="Odjavi se">
                        <IconButton
                          aria-label="bookmark Bahamas Islands"
                          // variant="plain"
                          color="neutral"
                          // size="sm"
                          onClick={() => {
                            if (isGuest) {
                              setIsGuest(false);
                            }
                            axios.get(URL + "logout");
                            setLoggedIn(false);
                          }}
                        >
                          <Logout />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </Box>
                </div>
              </Box>
              <ButtonGroup sx={{ mb: "20px" }}>
                <Button
                  onClick={() => {
                    setCheckingContent(!checkingContent);
                  }}
                  disabled={checkingContent}
                >
                  Podatki
                </Button>
                <Button
                  onClick={() => {
                    setCheckingContent(!checkingContent);
                  }}
                  disabled={!checkingContent}
                >
                  Koncerti
                </Button>
              </ButtonGroup>
              {checkingContent ? (
                <Editor isGuest={isGuest} />
              ) : (
                <Concerts isGuest={isGuest} />
              )}
              <AddNewUser
                setAddUser={setAddUser}
                open={addUser}
                setOpen={setAddUser}
              />
              <ChangePassword
                userEmail={userEmail}
                open={openNewPassword}
                setOpenNewPassword={setOpenNewPassword}
              />
            </Box>
          </Card>
        </body>
      </div>
      // </CssVarsProvider>
    );
  } else {
    return (
      // <CssVarsProvider defaultMode={mode}>
      <div className="App">
        <body>
          <Login
            setLoggedIn={setLoggedIn}
            setIsGuest={setIsGuest}
            setUserEmail={setUserEmail}
          />
        </body>
      </div>
      // </CssVarsProvider>
    );
  }
}

export default App;
function setPost(data: any) {
  throw new Error("Function not implemented.");
}
