import React from "react";
import Alert from "@mui/joy/Alert";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Snackbar, { SnackbarProps } from "@mui/joy/Snackbar";
import Button from "@mui/joy/Button";
import {
  CopyAll,
  Delete,
  Edit,
  Login,
  LoginOutlined,
  Remove,
  Save,
  Update,
} from "@mui/icons-material";
import { URL } from "../globals";

export default function EditAndSave(props: {
  disabled: boolean;
  editing: boolean;
  updateContent: (
    setUpdateSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  ) => Promise<boolean>;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  // const [success, setSuccess] = React.useState<boolean>(true);
  const [openUpdateSuccess, setOpenUpdateSuccess] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  async function getSuccessOfUpdate() {
    setLoading(true);
    let s = await props.updateContent(setOpenUpdateSuccess);
    setOpenUpdateSuccess(s);
    props.setEditing(s);
    if (s) {
      props.setEditing(false);
    }
    setLoading(false);
  }
  if (props.editing) {
    return (
      <>
        <Button
          onClick={() => {
            getSuccessOfUpdate();
          }}
          size="md"
          variant="solid"
          color="primary"
          startDecorator={<Save />}
          loading={loading}
        >
          Shrani
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          variant="outlined"
          size="md"
          color="neutral"
          onClick={() => {
            // setSuccess(false);
            props.setEditing(true);
            // setSuccess(false);
          }}
          sx={{ ml: "auto" }}
          startDecorator={<Edit />}
          disabled={props.disabled}
          loading={loading}
        >
          Uredi
        </Button>
        <Snackbar
          autoHideDuration={4000}
          open={openUpdateSuccess}
          variant="outlined"
          color="success"
          onClose={(event, reason) => {
            if (reason === "clickaway") {
              return;
            }
            setOpenUpdateSuccess(false);
          }}
        >
          Podatek uspešno posodobljen!
        </Snackbar>
      </>
    );
  }
}
