import * as React from "react";
import CssBaseline from "@mui/joy/CssBaseline";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import { DialogContent, DialogTitle, Modal, ModalDialog } from "@mui/joy";
import axios from "axios";
import { URL } from "../globals";

axios.defaults.withCredentials = true;

interface FormElements extends HTMLFormControlsCollection {
  email: HTMLInputElement;
  password: HTMLInputElement;
  persistent: HTMLInputElement;
}
interface SignInFormElement extends HTMLFormElement {
  readonly elements: FormElements;
}

export default function ChangePassword(props: {
  userEmail: String;
  setOpenNewPassword: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
}) {
  const [newPassword, setNewPassword] = React.useState("");
  const [newPassword2, setNewPassword2] = React.useState("");

  function change_password(email: string, password: string) {
    axios
      .post(
        URL + "change_passwd",
        { id: 1, email: email, passwd: password },
        { withCredentials: true },
      )
      .then(function (response) {
        console.log(response);
        // props.setOpenNewPassword(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  return (
    <>
      <Modal open={props.open} onClose={() => props.setOpenNewPassword(false)}>
        <ModalDialog size="lg" layout="center">
          <CssBaseline />
          <DialogTitle>Spremeni geslo</DialogTitle>
          <DialogContent>
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={(event: React.FormEvent<SignInFormElement>) => {
                  event.preventDefault();
                  const formElements = event.currentTarget.elements;
                  if (newPassword === newPassword2) {
                    change_password(
                      props.userEmail.toString(),
                      formElements.password.value,
                    );
                  } else {
                    console.log("Passwords do not match");
                  }
                }}
              >
                <FormControl required>
                  <FormLabel>Novo geslo</FormLabel>
                  <Input
                    type="password"
                    name="password"
                    required
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl required>
                  <FormLabel>Ponovi novo geslo</FormLabel>
                  <Input
                    type="password"
                    name="password2"
                    onChange={(e) => {
                      setNewPassword2(e.target.value);
                    }}
                  />
                </FormControl>
                <Stack gap={2} sx={{ mt: 5, px: 10 }}>
                  <Button type="submit" fullWidth>
                    Spremeni
                  </Button>
                  <Button
                    variant="soft"
                    onClick={() => {
                      props.setOpenNewPassword(false);
                    }}
                  >
                    Nazaj
                  </Button>
                </Stack>
              </form>
            </Stack>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
}
