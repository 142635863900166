import React from "react";
import "../App.css";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import IconButton from "@mui/joy/IconButton";
import Textarea from "@mui/joy/Textarea";
import Stack from "@mui/joy/Stack";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Typography from "@mui/joy/Typography";
import CardOverflow from "@mui/joy/CardOverflow";
import { CardContent, Grid } from "@mui/joy";
import axios from "axios";
import Add from "@mui/icons-material/Add";
import { CopyAll, Delete } from "@mui/icons-material";
import AddNewModal from "./AddNewModal";
import RemoveModal from "./RemoveModal";
import EditAndSave from "./EditAndSave";
import { URL } from "../globals";

type Content = {
  id: number;
  name_id: string;
  text_content: string;
  website: string;
};

function DostopnoNa(props: { name: string }) {
  if (props.name != "") {
    return (
      <>
        <Typography level="body-sm">
          Dostopno na: {URL + `/content/{props.name}/html`}{" "}
          <IconButton
            onClick={() => {
              navigator.clipboard.writeText(
                URL + "content/" + props.name + "/html",
              );
            }}
            sx={
              {
                // "--IconButton-size": "5px"
              }
            }
          >
            <CopyAll />
          </IconButton>
        </Typography>
      </>
    );
  } else {
    return <Typography level="body-sm">Dostopno na:</Typography>;
  }
}

export default function Editor(props: { isGuest: boolean }) {
  // Modals
  const [open, setOpen] = React.useState<boolean>(false);
  const [contentCreateSuccess, setContentCreateSuccess] =
    React.useState<boolean>(false);
  const [addUser, setAddUser] = React.useState<boolean>(false);

  const [openNewUser, setOpenNewUser] = React.useState<boolean>(false);

  const [submitted, setSubmitted] = React.useState<boolean>(false);

  const [openRemove, setOpenRemove] = React.useState<boolean>(false);

  const [disabledEdit, setDisabledEdit] = React.useState<boolean>(false);

  const [editing, setEditing] = React.useState<boolean>(false);

  // states for content selection and editing
  const [all_content, set_all_content] = React.useState<Content[]>([
    { id: 0, name_id: "", text_content: "", website: "" },
  ]);
  const [name, setName] = React.useState<string>("");
  const [content_ref, setContent_ref] = React.useState<string>("");
  const [websites, setWebsites] = React.useState<string[]>([""]);
  const [website, setWebsite] = React.useState<string>("");

  let names: String[] = [];

  React.useEffect(() => {
    axios.get(URL + "content/all").then(
      (response: {
        data: [
          {
            id: number;
            name_id: string;
            text_content: string;
            website: string;
          },
        ];
      }) => {
        console.log(response.data);
        let content_iter: Content[] = [];
        response.data.map((row) => {
          content_iter.push({
            id: row.id,
            name_id: row.name_id,
            text_content: row.text_content,
            website: row.website,
          } as Content);
          if (row.name_id != "") {
            names.push(row.name_id);
          }
          if (row.website != "" && !websites.includes(row.website)) {
            websites.push(row.website);
          }
        });
        set_all_content(content_iter);
      },
    );
  }, [submitted]);

  React.useEffect(() => {
    if (name != "") {
      axios.get(URL + "content/" + name).then((response: { data: Content }) => {
        console.log(response.data);
        setContent_ref(response.data.text_content);
        setEditing(false);
      });
    }
    setContent_ref("");
  }, [name]);

  async function updateContent(
    setUpdateSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  ): Promise<boolean> {
    let success: boolean = false;
    if (name != "") {
      await axios
        .post(URL + "content/" + name + "/update", {
          name_id: name,
          text_content: content_ref,
          website: website,
        })
        .then((response: { data: Content }) => {
          console.log(response.data);
          if (
            response.data.name_id === name &&
            response.data.text_content === content_ref
          ) {
            success = true;
            setUpdateSuccess(true);
            console.log("Setting success to: " + success);
            return true;
          }
          return false;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      console.log("Returning success: " + success);
    }
    return success;
  }

  const handleChangeOfName = (
    event: React.SyntheticEvent | null,
    newValue: string,
  ) => {
    setName(newValue);
  };

  return (
    <>
      <FormControl
        sx={{ display: { sm: "flex-column", md: "flex-row" }, gap: 2 }}
      >
        <Grid container spacing={4}>
          <Grid xs={5} sm={3}>
            <FormControl>
              <FormLabel>Izberi spletno stran</FormLabel>
              <Select
                value={website}
                onChange={(e, selectedWebsite) => {
                  if (selectedWebsite != null) {
                    setWebsite(selectedWebsite);
                  }
                  if (selectedWebsite === "") {
                    setContent_ref("");
                    setDisabledEdit(true);
                  }
                }}
              >
                {websites.map((ws) => {
                  return <Option value={ws}>{ws}</Option>;
                })}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Izberi podatek</FormLabel>
              <Select
                value={name}
                onChange={(e, selectedName) => {
                  if (selectedName != null) {
                    setName(selectedName);
                    setDisabledEdit(false);
                  }
                  if (selectedName === "") {
                    setDisabledEdit(true);
                  }
                }}
              >
                {all_content.map((row) => {
                  if (row.website === website) {
                    return <Option value={row.name_id}>{row.name_id}</Option>;
                  }
                })}
              </Select>
            </FormControl>
          </Grid>

          <Grid xs={2} sm={7}></Grid>
          <Grid xs={1} alignContent={"flex-end"} sx={{ mt: 7 }}>
            <Button
              size="md"
              variant="solid"
              color="primary"
              disabled={props.isGuest}
              startDecorator={<Add />}
              onClick={() => setOpen(true)}
            >
              Dodaj
            </Button>
          </Grid>
        </Grid>
        <Grid xs={2}></Grid>
      </FormControl>
      {/* </Box> */}
      <CardContent>
        <FormLabel>
          <Typography level="h4">Vsebina</Typography>
        </FormLabel>
        <Stack spacing={2} sx={{ my: 1 }}>
          <Textarea
            size="sm"
            minRows={8}
            maxRows={8}
            disabled={!editing}
            sx={{ mt: 1.5 }}
            value={name !== "" ? content_ref : ""}
            onChange={(e) => setContent_ref(e.currentTarget.value)}
            endDecorator={
              <Typography level="body-xs" sx={{ ml: "auto" }}>
                {content_ref.length} znakov
              </Typography>
            }
          />

          <FormHelperText sx={{ mt: 0.75, fontSize: "xs" }}></FormHelperText>
        </Stack>
        <Box>
          <Grid container spacing={0}>
            <Grid xs={2}>
              <Button
                onClick={(e) => setOpenRemove(true)}
                disabled={props.isGuest}
                size="md"
                variant="solid"
                color="danger"
                sx={{ ml: { xs: 2, md: 6 }, maxWidth: "200px" }}
                startDecorator={<Delete />}
              >
                Odstrani
              </Button>
            </Grid>
            <Grid xs={6} md={8}></Grid>
            <Grid xs={2}>
              <EditAndSave
                disabled={disabledEdit || props.isGuest}
                editing={editing}
                updateContent={updateContent}
                setEditing={setEditing}
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
      <AddNewModal
        open={open}
        websites={websites}
        setOpen={setOpen}
        setName={setName}
        setWebsite={setWebsite}
        setSubmitted={setSubmitted}
        submitted={submitted}
      />

      <RemoveModal
        openRemove={openRemove}
        setOpenRemove={setOpenRemove}
        name={name}
        setName={setName}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />

      <CardOverflow
        variant="soft"
        sx={{
          mt: 4,
          borderRadius: 0.5,
          alignContent: "center",
          bgcolor: "background.level1",
        }}
      >
        <DostopnoNa name={name} />
      </CardOverflow>
    </>
  );
}
